import __request__ from 'routing/request';
interface GrokPatternList {
    readonly patterns: GrokPattern[];
}
interface GrokPatternTestRequest {
    readonly grok_pattern: GrokPattern;
    readonly sampleData: string;
}
interface GrokPattern {
    readonly name: string;
    readonly pattern: string;
    readonly id: string;
    readonly content_pack: string;
}
interface PaginatedResponse_GrokPattern {
    readonly [_key: string]: Object;
}
interface InputStream {
}
/**
 * Get all existing grok patterns
 */
export function listGrokPatterns(): Promise<GrokPatternList> {
    return __request__('GET', '/system/grok', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Add a new named pattern
 */
export function createPattern(pattern: GrokPattern): Promise<GrokPattern> {
    return __request__('POST', '/system/grok', pattern, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Add a list of new patterns
 * @param import-strategy Strategy to apply when importing.
 */
export function bulkUpdatePatterns(patterns: GrokPatternList, importStrategy?: 'ABORT_ON_CONFLICT' | 'REPLACE_ON_CONFLICT' | 'DROP_ALL_EXISTING'): Promise<unknown> {
    return __request__('PUT', '/system/grok', patterns, { 'import-strategy': importStrategy }, {
        'Accept': ['application/json']
    });
}
/**
 * Add a list of new patterns
 * @param import-strategy Strategy to apply when importing.
 */
export function bulkUpdatePatternsFromTextFile(patterns: InputStream, importStrategy?: 'ABORT_ON_CONFLICT' | 'REPLACE_ON_CONFLICT' | 'DROP_ALL_EXISTING'): Promise<unknown> {
    return __request__('POST', '/system/grok', patterns, { 'import-strategy': importStrategy }, {
        'Accept': ['application/json']
    });
}
/**
 * Get existing grok patterns paged
 * @param sort The field to sort the result on
 * @param order The sort direction
 */
export function getPage(sort: 'name' | 'pattern' = 'name', page: number = 1, per_page: number = 50, query?: string, order: 'asc' | 'desc' = 'asc'): Promise<PaginatedResponse_GrokPattern> {
    return __request__('GET', '/system/grok/paginated', null, { 'page': page, 'per_page': per_page, 'query': query, 'sort': sort, 'order': order }, {
        'Accept': ['application/json']
    });
}
/**
 * Test pattern with sample data
 */
export function testPattern(pattern: GrokPatternTestRequest): Promise<unknown> {
    return __request__('POST', '/system/grok/test', pattern, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get the existing grok pattern
 */
export function listPattern(patternId: string): Promise<GrokPattern> {
    return __request__('GET', `/system/grok/${patternId}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Update an existing pattern
 */
export function updatePattern(patternId: string, pattern: GrokPattern): Promise<GrokPattern> {
    return __request__('PUT', `/system/grok/${patternId}`, pattern, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Remove an existing pattern by id
 */
export function removePattern(patternId: string): Promise<void> {
    return __request__('DELETE', `/system/grok/${patternId}`, null, {}, {
        'Accept': ['application/json']
    });
}
